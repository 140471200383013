import { connector } from 'redux/utils/connector';
import { STORE_NAME } from 'redux/modules/Energy/Quote/constants';
import applicationCheck from 'redux/modules/ApplicationCheck';
import { EnergyProductSelectionApi } from 'redux/modules/Energy/ProductSelectionForm/api';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { EntryFormApi } from 'redux/modules/EntryForm/api';
import { getTariff, TARIFF_LABELS } from 'redux/modules/Energy/Quote/constants';
import EnergyDetails from 'components/pages/EnergyDetails';

import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import EnergyQuoteApi from '../Quote/api';
import { getElectricityFieldsFromState } from './presentation';
import { getSelectedMobileProducts } from 'app/redux/modules/Mobile/api';

const canSwitchTariff = (eligibleForTariffChange, { gas, electricity }) => {
  if (!eligibleForTariffChange) return false;

  const hasElectricity = !!electricity;
  const hasGas = !!gas;
  const elecIsPrepayment =
    hasElectricity && electricity.tariff.name === TARIFF_LABELS.PREPAYMENT;
  const gasIsPrepayment =
    hasGas && gas.tariff.name === TARIFF_LABELS.PREPAYMENT;

  // if only one energy service selected and it's a prepayment
  if (hasElectricity && !hasGas && elecIsPrepayment) return false;
  if (hasGas && !hasElectricity && gasIsPrepayment) return false;

  return !(elecIsPrepayment && gasIsPrepayment);
};

const mapStateToProps = (state) => {
  const currentQuote = EnergyQuoteApi.getCurrentQuote(state);

  if (!currentQuote || !Object.keys(currentQuote).length) return {};

  const eligibleForTariffChange = ServiceSelectionApi.isEligibleForTariffChange(
    state
  );

  const showEnergyOnlyWarning =
    !EntryFormApi.isHomeOwner(state) &&
    PartnerLoginApi.isPartnerLoggedIn(state) &&
    ServiceSelectionApi.isEnergyOnly(state) &&
    !(
      EnergyQuoteApi.hasCurrentGasPrepaymentTariff(state) ||
      EnergyQuoteApi.hasCurrentElectricityPrepaymentTariff(state)
    );

  const fixedTariffDisabled = FeatureFlagsApi.getFlag(
    state,
    FLAGS.ENERGY_FIXED_DISABLED
  );
  const canSwitch = fixedTariffDisabled
    ? false
    : canSwitchTariff(eligibleForTariffChange, currentQuote);

  const selectedServices = ServiceSelectionApi.getSelectedServices(state);
  const selectedMobileProducts = getSelectedMobileProducts(state);
  const isMultiSim = selectedMobileProducts.length > 1;
  const mobileCheck = (isMultiSim) => {
    if (selectedServices.includes('mobile')) {
      return isMultiSim ? 1 : 0;
    }
    return 0;
  };

  const numberOfSelectedServices =
    selectedServices.length + mobileCheck(isMultiSim);

  return {
    selectedServices: selectedServices,
    numberOfSelectedServices: numberOfSelectedServices,
    tariff: getTariff(currentQuote),
    isTenant: !EntryFormApi.isHomeOwner(state),
    paymentPlan: EnergyProductSelectionApi.getPaymentPlan(state),
    offeredQuote: EnergyQuoteApi.getOfferedQuote(state),
    ...getElectricityFieldsFromState(state, currentQuote),
    isBannerEnabled:
      FeatureFlagsApi.getFlag(state, FLAGS.ENERGY_BANNER) &&
      !!currentQuote.electricity,
    canSwitchTariff: canSwitch,
    showEnergyOnlyWarning,
    smartEligible: EntryFormApi.getSmartEligibility(state),
    hasEvInterest: EnergyQuoteApi.getEvInterest(state),
  };
};

const EnergyDetailsContainer = connector(
  STORE_NAME,
  mapStateToProps
)(EnergyDetails);

export default applicationCheck(EnergyDetailsContainer);
